import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PermissionEnum } from '@app/generated/graphql';
import { PermissionService } from '@app/core/services/permission.service';
import * as Sentry from '@sentry/browser';

export enum PermissionStrategy {
  ANY = 'any',
  EVERY = 'every',
}

@Injectable()
export class PermissionGuard implements CanActivate {
  constructor(
    private router: Router,
    private permissionService: PermissionService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    // List of permissions or single permission
    const resourceId: PermissionEnum | PermissionEnum[] = route.data.resourceId;

    // Single permission
    if (!Array.isArray(resourceId)) {
      return this.checkOne(resourceId, route);
    }

    // Multiple permissions
    const checkStrategy: PermissionStrategy = route.data?.checkStrategy;
    switch (checkStrategy) {
      case PermissionStrategy.EVERY:
        return this.checkEvery(resourceId, route);
      case PermissionStrategy.ANY:
      default:
        return this.checkAny(resourceId, route);
    }
  }

  private checkEvery(
    permissions: PermissionEnum[],
    route: ActivatedRouteSnapshot
  ): Observable<boolean> {
    // If no permissions are provided, return false
    if (!permissions.length) {
      return from([this.handleResult(false, route)]);
    }

    return from(
      Promise.all(
        permissions.map((permission: PermissionEnum) =>
          this.permissionService.allow(permission)
        )
      )
    ).pipe(
      map((results: boolean[]) =>
        this.handleResult(results.every(Boolean), route)
      )
    );
  }

  private checkAny(
    permissions: PermissionEnum[],
    route: ActivatedRouteSnapshot
  ): Observable<boolean> {
    // If no permissions are provided, return false
    if (!permissions.length) {
      return from([this.handleResult(false, route)]);
    }

    return from(
      Promise.all(
        permissions.map((permission: PermissionEnum) =>
          this.permissionService.allow(permission)
        )
      )
    ).pipe(
      map((results: boolean[]) =>
        this.handleResult(results.some(Boolean), route)
      )
    );
  }

  private checkOne(
    permission: PermissionEnum,
    route: ActivatedRouteSnapshot
  ): Observable<boolean> {
    return from(this.permissionService.allow(permission)).pipe(
      map((result: boolean) => this.handleResult(result, route))
    );
  }

  private checkSettingsPageAccess(route: ActivatedRouteSnapshot): void {
    // if this user doesn't have access to the settings page we
    // probably have a permission misconfiguration on our hands
    if (route.data.resourceId === PermissionEnum.PagesSettings) {
      Sentry.captureMessage(
        `User does not have access to page: ${route.data.resourceId}`
      );
    }
  }

  private handleResult(
    isAllowed: boolean,
    route: ActivatedRouteSnapshot
  ): boolean {
    if (!isAllowed) {
      this.checkSettingsPageAccess(route);
      this.router.navigate(['/account/404']);
      return false;
    }

    return isAllowed;
  }
}
