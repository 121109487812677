import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Company, User, UserGQL, PermissionEnum } from '@app/generated/graphql';
import { UserHelper } from '../helpers/user.helper';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  currentUser: User;
  myCompany: Company;
  isAgrigateUser: boolean;
  initialized = false;

  constructor(private router: Router, private userGql: UserGQL) {}

  async allow(resourceId: PermissionEnum): Promise<boolean> {
    if (!resourceId) {
      return true;
    }

    await this.init();

    return this.resourceAllowed(resourceId);
  }

  async allowAll(resourceIds: PermissionEnum[]): Promise<boolean> {
    if (!resourceIds?.length) {
      return true;
    }

    await this.init();

    return resourceIds.every((resourceId) => this.resourceAllowed(resourceId));
  }

  async allowAny(resourceIds: PermissionEnum[]): Promise<boolean> {
    if (!resourceIds?.length) {
      return true;
    }

    await this.init();

    return resourceIds.some((resourceId) => this.resourceAllowed(resourceId));
  }

  isSuperUser(): boolean {
    // when a user gets set as a super user their permissions are set to null.
    // only available to AG1 users
    return this.currentUser.permissions === null && this.isAgrigateUser;
  }

  hasAnyPermissions(): boolean {
    return this.currentUser.permissions !== null;
  }

  hasPermission(resourceId: PermissionEnum): boolean {
    // Ensure permissions are initialized
    if (!this.initialized) {
      console.warn('Permissions not initialized yet.');
      return false;
    }
    return this.resourceAllowed(resourceId);
  }

  private resourceAllowed(resourceId: PermissionEnum): boolean {
    if (this.hasAnyPermissions()) {
      return this.resourceAllowedWithPermissions(resourceId);
    }

    // all resources allowed for super users
    return this.isSuperUser();
  }

  private resourceAllowedWithPermissions(resourceId: PermissionEnum): boolean {
    const permissions = this.currentUser.permissions.permissions;

    if (permissions.length === 0) {
      return true;
    }

    const permission = permissions.find((p) => p.key === resourceId);

    if (permission) {
      // At the moment the permissions are a blacklist, so this should always return false
      return permission.value;
    }

    return true;
  }

  private async init() {
    if (this.initialized) {
      return;
    }

    try {
      const user = await this.userGql.fetch().toPromise();
      this.currentUser = user.data.myUser;

      this.isAgrigateUser = UserHelper.isAgrigateUser(this.currentUser);

      this.initialized = true;
    } catch (error) {
      this.router.navigate(['account', '500'], { replaceUrl: true });
      throw error;
    }
  }

  get isAgrigateOneUser(): boolean {
    return this.isAgrigateUser;
  }
}
