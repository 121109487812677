import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import * as isoWeeksInYear from 'dayjs/plugin/isoWeeksInYear';
import * as isoWeek from 'dayjs/plugin/isoWeek';
import * as isLeapYear from 'dayjs/plugin/isLeapYear';

dayjs.extend(isoWeek);
dayjs.extend(isoWeeksInYear);
dayjs.extend(isLeapYear); // dependency for isoWeeksInYear

@Injectable({
  providedIn: 'root',
})
export class IsoWeekService {
  private _currentDate: dayjs.Dayjs = dayjs();

  setCurrentDate(date: dayjs.Dayjs): void {
    this._currentDate = date;
  }

  private getIsoWeek(date: dayjs.Dayjs): string {
    const year = date.isoWeekYear();
    const week = date.isoWeek();
    return `${year}-W${week.toString().padStart(2, '0')}`;
  }

  get currentWeek(): string {
    return this.getIsoWeek(this._currentDate);
  }

  previousWeek(weeksToSubtract: number = 1): string {
    const targetDate = this._currentDate.subtract(weeksToSubtract, 'week');
    return this.getIsoWeek(targetDate);
  }

  nextWeek(weeksToAdd: number = 1): string {
    const targetDate = this._currentDate.add(weeksToAdd, 'week');
    return this.getIsoWeek(targetDate);
  }

  private isCorrectStringFormat(week: string): boolean {
    return /^(\d{4})-W(\d{2})$/.test(week);
  }

  private isValidWeekNumber(year: number, week: number): boolean {
    // ISO week numbers are from 1-53, but some years only have 52 weeks
    const maxWeeks = dayjs().year(year).isoWeeksInYear();
    return week >= 1 && week <= maxWeeks;
  }

  getWeekDifference(startWeek: string, endWeek: string): number {
    if (!startWeek || !endWeek) {
      throw new Error('Week parameters are required');
    }

    if (
      !this.isCorrectStringFormat(startWeek) ||
      !this.isCorrectStringFormat(endWeek)
    ) {
      throw new Error('Week parameters must be in the format [YYYY]-W[WW]');
    }

    // Parse the week strings
    const startMatch: RegExpMatchArray = startWeek.match(/^(\d{4})-W(\d{2})$/);
    const endMatch: RegExpMatchArray = endWeek.match(/^(\d{4})-W(\d{2})$/);

    const startYear: number = parseInt(startMatch[1]);
    const endYear: number = parseInt(endMatch[1]);
    const startWeekNumber: number = parseInt(startMatch[2]);
    const endWeekNumber: number = parseInt(endMatch[2]);

    // Validate the week numbers
    if (
      !this.isValidWeekNumber(startYear, startWeekNumber) ||
      !this.isValidWeekNumber(endYear, endWeekNumber)
    ) {
      throw new Error('Invalid week number for year');
    }

    // Create dayjs objects for the start of each week
    const startDate = dayjs()
      .year(startYear)
      .isoWeek(startWeekNumber)
      .startOf('isoWeek');

    const endDate = dayjs()
      .year(endYear)
      .isoWeek(endWeekNumber)
      .startOf('isoWeek');

    // Calculate the difference in weeks
    return endDate.diff(startDate, 'week');
  }
}
